<script setup lang="ts">
    import {computed, defineAsyncComponent, ref, watch} from 'vue';
    import useCpBem from '~/composables/useCpBem';
    import CpButton from '~/components/cp/CpButton/CpButton.vue';
    import CpSectionHeader from '~/components/cp/CpSection/CpSectionHeader/CpSectionHeader.vue';
    import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
    import CpText from '~/components/cp/CpText/CpText.vue';
    import CpModal from '~/components/cp/CpModal/CpModal.vue';
    import CpTextParser from '~/components/cp/CpTextParser/CpTextParser.vue';
    import CpCardContact from '~/components/cp/CpCardContact/CpCardContact.vue';
    import CpAnimation from '~/components/cp/CpAnimation/CpAnimation.vue';
    import confetti from '~/assets/animations/confetti.json';

    const Vue3Lottie = defineAsyncComponent(() => import('vue3-lottie').then((module) => module.Vue3Lottie));
    const props = withDefaults(defineProps<{
        show: boolean
        kind?: 'info'|'warning'|'critical'|'success'
        title: string
        icon?: string
        subheading?: string
        body: string
        labelFirstButton: string
        typeFirstButton?: 'critical-hollow'|'primary-hollow'|'primary'
        labelSecondButton?: string
        typeSecondButton?: 'critical'|'primary'
        labelThirdButton?: string
        typeThirdButton?: 'critical'|'primary'
        // With the processedText we replaced all text that contain [ThirdParty]
        // and assign the property thirdParty
        thirdParty?: string
        // We search in the body [emailOne],[emailTwo] and replaced
        email?: {
            emailOne: string,
            emailTwo: string
        }
        // In case TextParser is needed for body
        useBoldText?: boolean
        formatBoldText?: boolean
      // for display CpCardContact
        hrefFirstButton?: string
        targetFirstButton?: string
        contact?: boolean
        useSlot?: boolean
        reduceGap?: boolean
        firstButtonTestId?: string
        secondButtonTestId?: string
        persistent?: boolean
        useConfettiAnimation?: boolean
    }>(), {
        kind: 'info',
        icon: 'info',
        typeFirstButton: 'primary-hollow',
        subheading: '',
        hrefFirstButton: undefined,
        targetFirstButton: '_self',
    });

    const emit = defineEmits<{
      (e: 'update:show', value: boolean): void
      (e: 'closeModal'): void
      (e: 'first-button'): void
      (e: 'second-button'): void
      (e: 'third-button'): void
    }>();

    const showAnimation = ref(false);

    const value = computed({
      get() {
        return props.show;
      },
      set(value) {
        emit('update:show', value);
        if (!value) {
          emit('closeModal');
        }
      },
    });

    const buttonType = computed(() => {
        const obj = {hollow: true, color: 'primary'};
        if (props.typeFirstButton.includes('-')) {
            obj.color = props.typeFirstButton.split('-')[0];
        } else {
            obj.hollow = false;
            obj.color = props.typeFirstButton;
        }
        return obj;
    });
    const type = computed(() => props.kind === 'info' ? 'primary' : props.kind);
    const processedText = computed(() => {
        const text = {
            title: props.title,
            subheading: props.subheading,
            body: props.body,
        };
        if (props.thirdParty !== undefined && props.thirdParty.length > 0) {
            text.title = text.title.replaceAll('[ThirdParty]', props.thirdParty);
            text.body = text.body.replaceAll('[ThirdParty]', props.thirdParty);
            text.subheading = text.subheading.replace('[ThirdParty]', props.thirdParty);
        }
        if (props.email !== undefined) {
            text.body = text.body.replaceAll('[emailOne]', props.email.emailOne);
            text.body = text.body.replaceAll('[emailTwo]', props.email.emailTwo);
        }
        return text;
    });

    const {b, e, em} = useCpBem('cp-modal-dialog');

    watch(() => value.value, () => {
     if (value.value && props.useConfettiAnimation) {
 showAnimation.value = true;
}
    }, {immediate: true});
</script>

<template>
    <div :class="b">
        <CpModal v-model:show="value" :persistent="persistent" @close-modal="value = false">
            <CpSectionHeader
                :title="processedText.title"
                button="close"
                type="h2"
                @header-action="value = false"
            />
            <div :class="em(`section-body`, { customGap: reduceGap })">
                <CpIconStandard v-if="kind !== 'success'" kind="extra-large" :icon="icon" :type="type" />
                <CpAnimation v-else name="success" />
                <CpText v-if="processedText.subheading!==''" variant="subheading">
                    {{ processedText.subheading }}
                </CpText>
                <CpTextParser
                    v-if="useBoldText && processedText.body"
                    :text="processedText?.body"
                    variant="body"
                    :format="!!formatBoldText"
                />
                <CpText v-if="!useBoldText" variant="body-regular">
                    {{ processedText?.body }}
                </CpText>
                <CpCardContact v-if="contact" action-description email />
                <slot v-if="useSlot" name="body" />
            </div>
            <div :class="e`button-container`">
                <CpButton
                    :color="buttonType.color"
                    :hollow="buttonType.hollow"
                    :label="labelFirstButton"
                    kind="label"
                    full-width
                    :href="hrefFirstButton"
                    :target="targetFirstButton"
                    :data-testid="firstButtonTestId ? firstButtonTestId : 'first-button-dialog'"
                    @click="$emit('first-button')"
                />
                <CpButton
                    v-if="labelSecondButton!==undefined && labelSecondButton.trim()!==''"
                    kind="label"
                    full-width
                    :color="typeSecondButton"
                    hollow
                    :label="labelSecondButton"
                    :data-testid="secondButtonTestId ? secondButtonTestId : 'second-button-dialog'"
                    @click="$emit('second-button')"
                />
                <CpButton
                    v-if="labelThirdButton!==undefined && labelThirdButton.trim()!==''"
                    kind="label"
                    full-width
                    :color="typeThirdButton"
                    hollow
                    :label="labelThirdButton"
                    @click="$emit('third-button')"
                />
            </div>
            <Vue3Lottie v-if="showAnimation"
                :animation-data="confetti"
                :loop="false"
                :class="e('confetti-animation')"
                @on-complete="showAnimation = false"
            />
        </CpModal>
    </div>
</template>

<style scoped lang="scss">
    $background-color: var(--color-white);
    .cp-modal-dialog {
        &__section-body {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
            padding: 16px;
            &--custom-gap {
              gap: $cp-gutter-small;
            }
        }
        &__button-container {
          width: 100%;
            border-top: 1px solid var(--color-neutral-extra-light);
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 16px;
        }
        &__confetti-animation {
          position: absolute;
          pointer-events: none;
        }
    }
</style>
